<template>
    <v-row class="match-height">
        <v-col cols="12" md="4">
            <v-row class="match-height">
                <v-col cols="12" sm="6">
                    <statistics-card-vertical
                        :change="salesOptions.change"
                        :color="salesOptions.color"
                        :icon="salesOptions.icon"
                        :statistics="salesOptions.statistics"
                        :stat-title="salesOptions.statTitle"
                        :subtitle="salesOptions.subtitle"></statistics-card-vertical>
                </v-col>
                <v-col cols="12" sm="6">
                    <statistics-card-vertical
                        :change="profitOptions.change"
                        :color="profitOptions.color"
                        :icon="profitOptions.icon"
                        :statistics="profitOptions.statistics"
                        :stat-title="profitOptions.statTitle"
                        :subtitle="profitOptions.subtitle"></statistics-card-vertical>
                </v-col>
                <v-col cols="12" sm="6">
                    <statistics-card-vertical
                        :change="clientsOptions.change"
                        :color="clientsOptions.color"
                        :icon="clientsOptions.icon"
                        :statistics="clientsOptions.statistics"
                        :stat-title="clientsOptions.statTitle"
                        :subtitle="clientsOptions.subtitle"></statistics-card-vertical>
                </v-col>

                <v-col cols="12" sm="6">
                    <statistics-card-vertical
                        :change="purchasesOptions.change"
                        :color="purchasesOptions.color"
                        :icon="purchasesOptions.icon"
                        :statistics="purchasesOptions.statistics"
                        :stat-title="purchasesOptions.statTitle"
                        :subtitle="purchasesOptions.subtitle"></statistics-card-vertical>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" md="8" sm="12">
            <v-card>
                <v-card-title>Movimentos</v-card-title>
                <v-card-text>
                    <ApexChartLineChart :options="apexChartSettings" />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="4"
            ><v-card>
                <v-card-text style="display: flex; justify-content: center">
                    <calendar></calendar>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
            <v-card>
                <v-card-title>Produção por máquina</v-card-title>
                <v-card-text>
                    <ApexCircularChart :options="donutChart" />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="5">
            <v-card>
                <v-card-title>Clientes</v-card-title>
                <v-card-text>
                    <Map />
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="8">
            <v-card>
                <v-card-title>Últimos projetos / encomendas</v-card-title>
                <v-card-text>
                    <project-tables />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4">
            <v-card>
                <v-card-title>Vendas (mensal)</v-card-title>
                <v-card-text>
                    <apex-column-chart :options="columnChart"></apex-column-chart>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue';

    import ApexChartLineChart from '../components/charts/ApexChartLineChart.vue';
    import ApexCircularChart from '../components/charts/ApexChartExpenseRatio.vue';
    import ApexColumnChart from '../components/charts/ApexChartDataScience.vue';

    import Calendar from '../components/calendar/DemoDatepickerBasic.vue';
    import Map from '../components/map/DashboardDemoMap.vue';

    import ProjectTables from '../components/tables/AnalyticsProjectsTable.vue';

    import { mdiTruck, mdiCurrencyEur, mdiAccountMultiple, mdiCart } from '@mdi/js';
    export default {
        components: {
            StatisticsCardVertical,
            ApexChartLineChart,
            Calendar,
            ApexCircularChart,
            ProjectTables,
            ApexColumnChart,
            Map,
        },

        data() {
            return {
                salesOptions: {
                    statTitle: 'Vendas',
                    icon: mdiTruck,
                    color: 'secondary',
                    subtitle: 'Desde a última semana',
                    statistics: '2.382',
                    change: '-42%',
                },

                profitOptions: {
                    statTitle: 'Ganhos',
                    icon: mdiCurrencyEur,
                    color: 'secondary',
                    subtitle: 'Desde a última semana',
                    statistics: '€21.300',
                    change: '+6.65%',
                },

                clientsOptions: {
                    statTitle: 'Clientes',
                    icon: mdiAccountMultiple,
                    color: 'secondary',
                    subtitle: 'Desde a última semana',
                    statistics: '12.212',
                    change: '+5.25%',
                },
                purchasesOptions: {
                    statTitle: 'Compras',
                    icon: mdiCart,
                    color: 'secondary',
                    subtitle: 'Desde a última semana',
                    statistics: '64',
                    change: '-2.25%',
                },

                apexChartSettings: {
                    series: [
                        {
                            name: 'Vendas(€)',
                            data: [2115, 1562, 1584, 1892, 1587, 1923, 2566, 2448, 2805, 3438, 2917, 3327],
                        },
                    ],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            curve: 'smooth',
                        },
                        legend: {
                            show: true,
                            position: 'top',
                            horizontalAlign: 'left',
                            fontSize: '14px',
                            fontFamily: '"Inter", sans-serif',
                        },
                        grid: {
                            xaxis: {
                                lines: {
                                    show: true,
                                },
                            },
                        },
                        xaxis: {
                            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        },
                        yaxis: {
                            // opposite: isRtl
                        },
                        fill: {
                            type: 'gradient',
                            gradient: {
                                opacityFrom: 0.6,
                                opacityTo: 0.8,
                            },
                        },
                        tooltip: {
                            shared: false,
                        },
                        colors: ['#0070f3'],
                    },
                },

                donutChart: {
                    series: [1689, 4306, 3801],
                    chartOptions: {
                        legend: {
                            show: true,
                            position: 'bottom',
                            fontSize: '14px',
                            fontFamily: '"Inter", sans-serif',
                        },
                        colors: ['#0070f3', '#f44336', '#ff9800'],
                        dataLabels: {
                            enabled: true,
                            formatter(val) {
                                // eslint-disable-next-line radix
                                return `${parseInt(val)}%`;
                            },
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize: '2rem',
                                            fontFamily: '"Inter", sans-serif',
                                        },
                                        value: {
                                            fontSize: '1rem',
                                            fontFamily: '"Inter", sans-serif',
                                            formatter(val) {
                                                // eslint-disable-next-line radix
                                                return `${parseInt(val)}`;
                                            },
                                        },
                                        total: {
                                            show: false,
                                            fontSize: '1.5rem',
                                            label: '',
                                            formatter() {
                                                return '31%';
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        labels: ['Máquina 1', 'Máquina 2', 'Máquina 3'],
                        responsive: [
                            {
                                breakpoint: 992,
                                options: {
                                    chart: {
                                        height: 380,
                                    },
                                    legend: {
                                        position: 'bottom',
                                    },
                                },
                            },
                            {
                                breakpoint: 576,
                                options: {
                                    chart: {
                                        height: 320,
                                    },
                                    plotOptions: {
                                        pie: {
                                            donut: {
                                                labels: {
                                                    show: true,
                                                    name: {
                                                        fontSize: '1.5rem',
                                                    },
                                                    value: {
                                                        fontSize: '1rem',
                                                    },
                                                    total: {
                                                        fontSize: '1.5rem',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    legend: {
                                        show: true,
                                    },
                                },
                            },
                        ],
                    },
                },
                columnChart: {
                    series: [
                        {
                            name: 'Este ano',
                            data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
                        },
                    ],
                    chartOptions: {
                        chart: {
                            stacked: false,
                            toolbar: {
                                show: false,
                            },
                        },
                        colors: ['#0070f3'],
                        /* plotOptions: {
            bar: {
              columnWidth: '15%',
              colors: {
                backgroundBarColors: [
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                ],
                backgroundBarRadius: 10,
              },
            },
          }, */
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            show: true,
                            position: 'top',
                            fontSize: '14px',
                            fontFamily: '"Inter", sans-serif',
                            horizontalAlign: 'left',
                        },
                        stroke: {
                            show: true,
                            colors: ['transparent'],
                        },
                        grid: {
                            xaxis: {
                                lines: {
                                    show: true,
                                },
                            },
                        },
                        xaxis: {
                            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                        },
                        yaxis: {
                            // opposite: isRtl,
                        },
                        fill: {
                            opacity: 1,
                        },
                    },
                },
            };
        },
    };
</script>
