<template>
    <v-card>
        <v-data-table
            :headers="tableColumnHeaders"
            :items="userListData"
            item-key="fullName"
            hide-default-footer
            class="table-rounded">
            <!-- name -->
            <template #[`item.fullName`]="{ item }">
                <div class="d-flex align-center">
                    <v-avatar
                        :color="item.avatar ? '' : 'primary'"
                        :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                        size="32">
                        <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
                        <span v-else class="font-weight-medium">{{ item.fullName.slice(0, 2).toUpperCase() }}</span>
                    </v-avatar>

                    <div class="d-flex flex-column ms-3">
                        <span class="d-block text--primary font-weight-semibold text-truncate">{{
                            item.fullName
                        }}</span>
                        <small>{{ item.userName }}</small>
                    </div>
                </div>
            </template>

            <template #[`item.role`]="{ item }">
                <div class="d-flex align-center">
                    <v-avatar
                        size="30"
                        :color="role[item.role].color"
                        :class="`v-avatar-light-bg ${role[item.role].color}--text me-3`">
                        <v-icon size="20" :color="role[item.role].color">
                            {{ role[item.role].icon }}
                        </v-icon>
                    </v-avatar>
                    <span>{{ item.role }}</span>
                </div>
            </template>

            <!-- status -->
            <template #[`item.status`]="{ item }">
                <v-chip
                    small
                    :color="statusColor[item.status]"
                    :class="`${statusColor[item.status]}--text`"
                    class="v-chip-light-bg font-weight-semibold text-capitalize">
                    {{ item.status }}
                </v-chip>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiChartTimelineVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCogOutline,
    } from '@mdi/js';

    export default {
        setup() {
            const statusColor = {
                /* eslint-disable key-spacing */
                inativo: 'secondary',
                ativo: 'success',
                pendente: 'warning',
                terminado: 'info',
                cancelado: 'error',
                /* eslint-enable key-spacing */
            };

            const role = {
                maintainer: { icon: mdiChartTimelineVariant, color: 'primary' },
                subscriber: { icon: mdiAccountOutline, color: 'warning' },
                editor: { icon: mdiPencilOutline, color: 'info' },
                author: { icon: mdiCogOutline, color: 'success' },
            };

            const userListData = [
                {
                    avatar: '1.png',
                    fullName: 'Joseph Wheeler',
                    userName: '@gslixby0',
                    primaryEmail: 'nuroani@icpair.com',
                    role: 'maintainer',
                    status: 'inativo',
                    projectName: 'Projeto I',
                    startAt: '01/01/2022',
                    endAt: '31/07/2022',
                },
                {
                    avatar: '2.png',
                    fullName: 'May Lloyd',
                    userName: '@hredmore1',
                    primaryEmail: 'jeju@ma.co.uk',
                    role: 'subscriber',
                    status: 'ativo',
                    projectName: 'Projeto II',
                    startAt: '01/01/2022',
                    endAt: '31/07/2022',
                },
                {
                    avatar: '3.png',
                    fullName: 'William Mckinney',
                    userName: '@msicely2',
                    primaryEmail: 'suumde@ha.co.uk',
                    role: 'subscriber',
                    status: 'pendente',
                    projectName: 'Projeto III',
                    startAt: '01/01/2022',
                    endAt: '31/07/2022',
                },
                {
                    fullName: 'Isabel Briggs',
                    userName: '@crisby3',
                    primaryEmail: 'temiwiho@ohacma.gov',
                    role: 'editor',
                    status: 'ativo',
                    projectName: 'Encomenda I',
                    startAt: '01/01/2022',
                    endAt: '31/07/2022',
                },
                {
                    avatar: '5.png',
                    fullName: 'Warren Clarke',
                    userName: '@mhurran4',
                    primaryEmail: 'hirasles@zozzetkuv.edu',
                    role: 'maintainer',
                    status: 'cancelado',
                    projectName: 'Encomenda II',
                    startAt: '01/01/2022',
                    endAt: '31/07/2022',
                },
                {
                    avatar: '4.png',
                    fullName: 'Adeline Bennett',
                    userName: '@shalstead5',
                    primaryEmail: 'boz@peh.co.uk',
                    role: 'author',
                    status: 'terminado',
                    projectName: 'Encomenda III',
                    startAt: '01/01/2022',
                    endAt: '31/07/2022',
                },
            ];

            const tableColumnHeaders = [
                { text: 'Nome', value: 'projectName' },
                { text: 'Data Início', value: 'startAt' },
                { text: 'Data Fim', value: 'endAt' },
                { text: 'Estado', value: 'status' },
                { text: 'Responsável', value: 'fullName' },
            ];

            return {
                userListData,
                statusColor,
                role,
                tableColumnHeaders,

                // icons
                icons: {
                    mdiSquareEditOutline,
                    mdiDotsVertical,
                },
            };
        },
    };
</script>
