<template>
    <vue-apex-charts type="donut" height="350" :options="options.chartOptions" :series="options.series" />
    <!--  <vue-apex-charts
    type="donut"
    height="350"
    :options="apexChatData.donutChart.chartOptions"
    :series="apexChatData.donutChart.series"
  /> -->
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        props: ['options'],
        components: {
            VueApexCharts,
        },
    };
</script>
