<template>
    <vue-apex-charts type="bar" height="400" :options="options.chartOptions" :series="options.series" />
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        props: ['options'],
        components: {
            VueApexCharts,
        },
    };
</script>
